<template>
  <div class="header-content">
    <div class="autozone-header">
      <router-link to="/" style="display: inherit">
        <img class="autozone-logo" :src="cmsHeaderLogo" />
      </router-link>
      <div class="search-container">
        <el-input
          @focus="inputFocused"
          class="search-input"
          placeholder="search"
          type="text"
          v-model="searchTerm"
          autocomplete="off"
          @keydown.enter="searchClicked"
          :input-style="{
            borderRadius: '0px',
            borderTopLeftRadius: '15px',
            borderBottomLeftRadius: '15px',
            borderRight: 'none',
            height: '30px',
            width: '300px',
            lineHeight: '0px',
          }"
        ></el-input>
        <div class="icon-container">
          <Icon
            @click="searchClicked"
            container="search-icon"
            icon="icon-fa-search"
          />
        </div>
        <YMMIcon />
      </div>
    </div>
  </div>
</template>

<script>
import { useGetters, useMutations } from '@/lib/helpers'
import { ref } from 'vue'
import YMMIcon from '@/components/ymm/YMMIcon'
import Icon from '@/components/buttons/Icon'
import { getAPIObjFor } from '@/lib/ymm/YMMApi'
import { useRouter } from 'vue-router'
export default {
  components: { YMMIcon, Icon },
  setup () {
    const { setSelectedPart } = useMutations(['setSelectedPart'])
    const { cmsHeaderLogo } = useGetters(['cmsHeaderLogo'])
    const searchTerm = ref('')
    const NO_RESULTS_FOUND_TEXT = 'Try Searching by Vehicle'
    const router = useRouter()

    const searchClicked = async () => {
      const { apiRequestUrl, apiRequest } = getAPIObjFor('Search')(
        searchTerm.value
      )

      let searchRequest
      if (apiRequest) searchRequest = await apiRequest(apiRequestUrl)
      if (searchRequest) {
        setSelectedPart(searchRequest)
        router.push({
          name: 'PDP',
          params: { id: searchRequest.product_part.number }
        })
      } else {
        searchTerm.value = NO_RESULTS_FOUND_TEXT
      }
    }
    const inputFocused = () => {
      if (searchTerm.value === NO_RESULTS_FOUND_TEXT) searchTerm.value = ''
    }
    return { searchTerm, cmsHeaderLogo, searchClicked, inputFocused }
  }
}
</script>

<style lang="scss" >
#nav {
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.5));
  padding: 10px;
  background-color: #eb6914;
  display: flex;
  justify-content: center;

  .nav-container {
    min-width: 600px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  a {
    display: flex;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    flex-grow: 1;
    justify-content: center;

    &.router-link-exact-active {
      font-size: 18px;
      filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.5));
    }
  }
}

.search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  i {
    color: white;
  }
  .search-input {
    width: 300px;
    line-height: 0px;
    .el-input__inner {
      border-color: $mf-border-color !important;
    }
  }
  .icon-container {
    width: 40px;
    height: 30px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    cursor: pointer;
    border-left-color: #0000;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-right-color: #ddd;
    .search-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #000;
      width: 44px;
      height: 30px;
      cursor: pointer;
    }
  }
}

.autozone-header {
  justify-content: space-between;
  height: 60px;
  background-color: var(--header-bg-color);
  display: flex;
  padding: 0 28px;
  a {
    display: flex;
    align-items: center;
  }
  img {
    &.autozone-logo {
      width: auto;
      height: 30px;
    }
    &.mf-logo {
      width: 200px;
    }
  }
}
</style>
