import { getAPIObjFor } from '@/lib/ymm/YMMApi'
import ButterCMS from 'buttercms'

export async function getClientCMSData () {
  const host = window.location.host
  const splitMF = host.split('.')[0]
  let microsite = splitMF.length > 1 && !splitMF.includes('localhost') ? splitMF : 'autozone'
  if (!splitMF.includes('microsite-'))microsite = 'microsite-' + microsite
  return await getCMSConfigObject(microsite)
}

export async function getCMSConfigObject (_resource) {
  const apiResourceGroup = _resource
  const { apiRequestUrl, apiRequest } = getAPIObjFor('CMSApiKey')(_resource)
  let getCMSKeyForMicrosite = false
  // keep log for live debugging
  console.log('api req url', apiRequestUrl)
  if (apiRequestUrl && apiRequest) {
    getCMSKeyForMicrosite = await apiRequest(apiRequestUrl)
  }
  const cmsApiResourceKey = getCMSKeyForMicrosite || 'nokey'
  const Butter = ButterCMS(cmsApiResourceKey)
  const cmsData = await Butter.page.retrieve('*', apiResourceGroup)
    .then(function (resp) {
      return resp.data.data
    })
    .catch(function (resp) {
      console.log('%cERROR Butter Data, using default values:', 'color:red', resp)
      return false
    })

  return cmsData
}
