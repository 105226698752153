// eslint-disable-next-line no-unused-vars
import axios from 'axios'
// import { jwtDecode } from 'jwt-decode'
// const API = process.env.NODE_ENV === 'production' ? 'http://localhost:5000/api' : 'http://localhost:5000/api'
// const API = process.env.NODE_ENV === 'production' ? 'https://20230112mwmsmaindatasvc.azurewebsites.net/api' : 'https://20230112mwmsmaindatasvc.azurewebsites.net/api'
const API = process.env.NODE_ENV === 'production' ? 'https://20230112mwmsmaindatasvc.azurewebsites.net/api' : 'https://20230112mwmsmaindatasvc.azurewebsites.net/api'
const tokenInput = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Inplc1M5eUdocjFXMmRjc1BXMkEtZiJ9.eyJpc3MiOiJodHRwczovL2Rldi1mcnRkamQ3Ny51cy5hdXRoMC5jb20vIiwic3ViIjoiVkt5WmIxMDhoODFxQVQ5a2F2a2ZBanU5eGppUEpuallAY2xpZW50cyIsImF1ZCI6Imh0dHBzOi8vaGVhZGxlc3NtaWRkbGV3YXJlLmF6dXJld2Vic2l0ZXMubmV0IiwiaWF0IjoxNzMzMTc3Nzg2LCJleHAiOjE3MzU3Njk3ODYsInNjb3BlIjoicmVhZDp2ZWhpY2xlcyByZWFkOnBhcnRzIiwiZ3R5IjoiY2xpZW50LWNyZWRlbnRpYWxzIiwiYXpwIjoiVkt5WmIxMDhoODFxQVQ5a2F2a2ZBanU5eGppUEpualkifQ.YyElbzuByZuOyvrqbJG_1KiJ-IquDNgnZTOtFFsHd_Iws2kXoIz7L3AQ25jTLJgscqVWy_GfouDMXU1rvzcO-d9wCK_-AREg-z6FApF35WSppAbUzcJZ7cJdDyUbSXIKLP3L9qSKhcDIe1PwIxeacztCNO9pGUs1ufXV8BFEF6GLdk5H6CfFSdzp0cK-ahZmOKmUcCW-99QhgmfuIAxZeimKsXryKz-2UP-l0joxWz0mBu87_fnHD5sa3j4zf_KkB-XvpsQfmXyYNxSnMPEIDJfLNs1AwWq3k83tU4YQDP_R5CUt7SCjJLiSqgh2lOHdvLdqdgdFkdexYFxkbdFURQ'

let ymmsAllData = null
let yearMakesData = null
let yearMakeModelsData = null
let yearMakeModelSubModelsData = null
// let yearMakeModelSubModelEnginesData = null

const fetchAccessToken = async (clientKey) => {
  const token = await axios.post(`${API}/v2/Auth0/AccessToken?clientKey=${clientKey}`).then(res => res.data).catch(async (error) => {
    console.error(error)
    return ''
  })
  return token
}

const isAccessTokenExpired = (token) => {
  const decodedToken = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())
  return Date.now() > (decodedToken.exp * 1000)
}

const getAccessToken = async () => {
  var token = localStorage.getItem('middlewareAccessToken')

  if (token) {
    if (isAccessTokenExpired(token)) {
      const newToken = await fetchAccessToken(token)
      localStorage.setItem('middlewareAccessToken', newToken)
      return newToken
    } else {
      return token
    }
  } else {
    const newToken = await fetchAccessToken(tokenInput)
    localStorage.setItem('middlewareAccessToken', newToken)
    return newToken
  }
}

const requestResource = async (_url) => {
  let errorMessage = false

  var token = await getAccessToken()
  const bearer = 'Bearer ' + token

  const request = await axios.get(_url, { headers: { Authorization: bearer } }).then(res => res.data).catch(async (error) => {
    errorMessage = error
    return false
  })

  console.log('REQUESTING DATA FROM THIS RESOURCE:::', { URL: _url, PAYLOAD: request, ERROR: errorMessage })
  return request
}

const getQueryData = (_queryParams, _apiEndpoint, _resourceRequest = requestResource) => {
  // queryString = (string, of query params) or (false, if some query params were missing)
  const queryString = Object.keys(_queryParams).reduce((_str, _queryParam) => {
    const paramValue = _queryParams[_queryParam]
    if (paramValue && _str !== false) { _str += `${_queryParam}=${paramValue}&` } else { _str = false }

    return _str
  }, '')

  if (queryString === false) { return false }

  const apiRequestUrl = _apiEndpoint + queryString
  const apiRequest = _resourceRequest
  // RETURNS object containing the api query url AND the request for that query
  return { apiRequestUrl, apiRequest }
}

const ensureYmmsDataLoaded = () => {
  if (ymmsAllData) {
    return Promise.resolve(ymmsAllData)
  }
  return requestResource(`${API}/v2/${process.env.VUE_APP_SELECTED_COMPANY}/YmmsAll`)
    .then((response) => {
      ymmsAllData = response
      return ymmsAllData
    })
    .catch((error) => {
      console.error('Error loading ymms data:', error)
      throw error
    })
}

const getLabel = (_item, _labelKey) => {
  return _item[_labelKey]
}

const getValue = (_item, _valueKey, _labelKey) => {
  const valKey = _valueKey === '' ? _labelKey : _valueKey
  return _item[valKey]
}

// example: https://middlewarewowapi.azurewebsites.net/api/v1/Vehicles/Years
// const getYearsQuery = () => getQueryData({}, `${API}/v1/Microsite/Years?`, yearsDataFormater)
// const yearsDataFormater = async (_url) => {
//   const data = await requestResource(_url)
//   const formattedData = data.sort((cur, prev) => {
//     if (cur.YearId > prev.YearId) { return 1 } else { return -1 }
//   })
//   return formattedData
// }
const getYearsQuery = () => {
  return ensureYmmsDataLoaded().then(() => {
    const years = ymmsAllData.map((option) => ({
      value: getValue(option, 'yearID', 'yearID'),
      label: getLabel(option, 'yearID')
    }))
    const sortedYears = years.sort((a, b) => b.value - a.value) // Sort by year
    return sortedYears
  })
}

// example: https://middlewarewowapi.azurewebsites.net/api/v1/Vehicles/Make/ByYear?year=1981
// const getMakesQuery = (_groupState) => getQueryData({
//   year: _groupState.Year.value || false
// }, `${API}/v1/Microsite/Make/ByYear?`)
const getMakesQuery = (_groupState) => {
  return ensureYmmsDataLoaded().then(() => {
    const selectDataYear = ymmsAllData.find(item => item.yearID === _groupState.Year.value)
    const jsonDataMakes = selectDataYear?.makes || []
    yearMakesData = jsonDataMakes

    const uniqueMakes = new Set()
    const makes = jsonDataMakes.map(option => {
      const makeID = getValue(option, 'MakeID', 'MakeName')
      const makeName = getLabel(option, 'MakeName')

      if (!uniqueMakes.has(makeID)) {
        uniqueMakes.add(makeID)
        return { value: makeID, label: makeName }
      }

      return null
    }).filter(option => option !== null)

    const sortedMakes = makes.sort((a, b) => a.label.localeCompare(b.label))
    return sortedMakes
  })
}

// example: https://middlewarewowapi.azurewebsites.net/api/v1/Vehicles/Models/ByYearMake?year=1981&makeID=54
// const getModelsQuery = (_groupState) => getQueryData({
//   year: _groupState.Year.value || false,
//   makeID: _groupState.Make.value || false
// }, `${API}/v1/Microsite/Models/ByYearMake?`)
const getModelsQuery = (_groupState) => {
  const selectedYearMake = yearMakesData.find(option => option.MakeID === _groupState.Make.value)
  const jsonDataModels = selectedYearMake?.Models || []
  yearMakeModelsData = jsonDataModels

  const newModelsList = jsonDataModels.map(option => ({
    value: option.ModelID,
    label: option.ModelName
  }))
  const sortedModels = newModelsList.sort((a, b) => a.label.localeCompare(b.label))
  return sortedModels
}

// example: https://middlewarewowapi.azurewebsites.net/api/v1/Vehicles/SubModels/ByYearMakeModel?year=1985&makeID=54&ModelID=666
// const getSubModelsQuery = (_groupState) => getQueryData({
//   year: _groupState.Year.value || false,
//   makeID: _groupState.Make.value || false,
//   ModelID: _groupState.Model.value || false
// }, `${API}/v1/Microsite/SubModels/ByYearMakeModel?`)
const getSubModelsQuery = (_groupState) => {
  const selectedYearMakeModel = yearMakeModelsData.find(option => option.ModelID === _groupState.Model.value)
  const jsonDataSubModels = selectedYearMakeModel?.SubModels || []
  yearMakeModelSubModelsData = jsonDataSubModels

  const newSubModelsList = jsonDataSubModels.map(option => ({
    value: option.SubModelID,
    label: option.SubModelName
  }))
  const sortedSubModels = newSubModelsList.sort((a, b) => a.label.localeCompare(b.label))
  return sortedSubModels
}

// example: https://middlewarewowapi.azurewebsites.net/api/v1/Vehicles/Engines/ByYearMakeModelSubmodel?year=1985&makeID=54&ModelID=666&SubModelID=20
// const getEnginesQuery = (_groupState) => getQueryData({
//   year: _groupState.Year.value || false,
//   makeID: _groupState.Make.value || false,
//   ModelID: _groupState.Model.value || false,
//   SubModelId: _groupState.SubModel.value || false
// }, `${API}/v1/Microsite/Engines/ByYearMakeModelSubmodel?`, engineDataFormater)
const getEnginesQuery = (_groupState) => {
  const selectedYearMakeModelSubModel = yearMakeModelSubModelsData.find(option => option.SubModelID === _groupState.SubModel.value)
  const jsonDataEngines = selectedYearMakeModelSubModel?.EngineLabelPartTypes || []

  const newEnginesList = jsonDataEngines.map((option, index) => ({
    value: index,
    label: option.EngineLabel
  }))

  const sortedEngines = newEnginesList.sort((a, b) => a.label.localeCompare(b.label))
  return sortedEngines
}

// const engineDataFormater = async (_url) => {
//   const data = await requestResource(_url)
//   const formattedData = data.reduce((_arr, _engine) => {
//     const engine = {
//       label: `${
//                 _engine.BlockType
//             }${
//                 _engine.Cylinders
//             } ${
//                 _engine.Liter
//             }L ${
//                 _engine.VehicleTypeGroupName === 'Light Duty' ? '' : _engine.VehicleTypeGroupName
//             }`,
//       value: _engine.EngineBaseID
//     }
//     _arr.push(engine)
//     return _arr
//   }, [])
//   return formattedData
// }

// https://middlewaremicrositeflatdata20211026.azurewebsites.net/api/v1/Parts/Search?vendor=bre&type=sku&searchvalue=106-0002
const getSearchQuery = (searchValue, type = 'sku', vendor = 'bre') => getQueryData({
  searchValue,
  type,
  vendor
}, `${API}/v1/Parts/Search?`, searchFormatter)

const searchFormatter = async (_url) => {
  const searchedProductData = await requestResource(_url)
  let searchedPart = false
  if (searchedProductData?.Parts.length) {
    searchedPart = searchedProductData.Parts[0]
    searchedPart.sku = searchedPart.sku[0]
    searchedPart.part_type = searchedPart.part_type[0]
  }
  return searchedPart
}

// https://middlewaremicrositeflatdata20211026.azurewebsites.net/api/v1/Vehicles/CompatibleVehicles/ByPartNumber?partNumber=106-0485
// const getCompatibleVehiclesQuery = (partNumber) => getQueryData({
//   partNumber
// }, `${API}/v1/Microsite/CompatibleVehicles/ByPartNumber?`, compatibleVehiclesDataFormatter)
const getCompatibleVehiclesQuery = async (partNumber) => {
  const data = await requestResource(`${API}/v2/${process.env.VUE_APP_SELECTED_COMPANY}/Vehicles/ByPartNumber?partNumber=${partNumber}`)
  // const updatedCPEngines = data.Vehicles.map((car) => {
  //   const { BlockType, Cylinders, Liter } = car
  //   const Engine = `${BlockType}${Cylinders} ${Liter}`
  //   car.Engine = Engine
  //   return car
  // })
  const updatedCPEngines = data.Vehicles

  // get our raw data and organize makes into letter object {'f':{letter:'f',data:{'ford':[car1,car2]}}}
  const formattedData = updatedCPEngines.reduce((_obj, _car) => {
    // const { Make } = _car
    const Make = _car?.base_vehicle?.make?.name

    const makeFirstLetter = Make.charAt(0).toLowerCase()
    const objContainsLetter = (makeFirstLetter in _obj)
    // if we dont have letter in our object, create a letter object for that letter
    if (!objContainsLetter) {
      _obj[makeFirstLetter] = { letter: makeFirstLetter, data: {} }
    }
    // if we dont have that make in that letter object, create that make object
    const letterObjContainsMake = (Make in _obj[makeFirstLetter].data)
    if (!letterObjContainsMake) {
      _obj[makeFirstLetter].data[Make] = []
    }
    _obj[makeFirstLetter].data[Make].push(_car)
    return _obj
  }, {})

  // requests cp data then alphabetize letters and makes within letters
  const alphabetizedCPbyMake = Object.keys(formattedData).reduce((arr, _letterKey) => {
    // get the current letter object wich contains the makes for that letter in 'data'
    const letterObj = formattedData[_letterKey]
    // alphabetize the makes keys within data
    const sortedDataKeys = Object.keys(letterObj.data).sort((cur, prev) => cur.toLowerCase() > prev.toLowerCase() ? 1 : -1)
    // create new data object based on the sorted keys
    const sortedMakes = sortedDataKeys.map((_make) => letterObj.data[_make])
    const sortedData = sortedMakes.map((_makeArr) => {
      return _makeArr.sort((a, b) => {
        // if years are different, put smallest year ahead
        if (a.Year !== b.Year) return a.Year < b.Year ? 1 : -1
        //  if years are same and models are different, put smallest model ahead
        if (a.Year === b.Year && a.Model !== b.Model) return a.Model < b.Model ? -1 : 1
        if (a.Year === b.Year && a.Model === b.Model && a.SubModel !== b.SubModel) return a.SubModel < b.SubModel ? -1 : 1
        if (a.Year === b.Year && a.Model === b.Model && a.SubModel === b.SubModel) return a.Cylinders < b.Cylinders ? -1 : 1

        else return 0
      })
    })
    // update existing data with sorted data
    letterObj.data = sortedData
    // add letterObj with sorted data to our letter array
    arr.push(letterObj)
    // sort letter array with new letter object added
    arr.sort((cur, prev) => cur.letter > prev.letter ? 1 : -1)

    return arr
  }, [])
  return alphabetizedCPbyMake
}

// const compatibleVehiclesDataFormatter = async (_url) => {
//   const data = await requestResource(_url)
//   // use car data to build engine value and then add to car object
//   const updatedCPEngines = data.map((car) => {
//     const { BlockType, Cylinders, Liter } = car
//     const Engine = `${BlockType}${Cylinders} ${Liter}`
//     car.Engine = Engine
//     return car
//   })

//   // get our raw data and organize makes into letter object {'f':{letter:'f',data:{'ford':[car1,car2]}}}
//   const formattedData = updatedCPEngines.reduce((_obj, _car) => {
//     const { Make } = _car
//     const makeFirstLetter = Make.charAt(0).toLowerCase()
//     const objContainsLetter = (makeFirstLetter in _obj)
//     // if we dont have letter in our object, create a letter object for that letter
//     if (!objContainsLetter) {
//       _obj[makeFirstLetter] = { letter: makeFirstLetter, data: {} }
//     }
//     // if we dont have that make in that letter object, create that make object
//     const letterObjContainsMake = (Make in _obj[makeFirstLetter].data)
//     if (!letterObjContainsMake) {
//       _obj[makeFirstLetter].data[Make] = []
//     }
//     _obj[makeFirstLetter].data[Make].push(_car)
//     return _obj
//   }, {})

//   // requests cp data then alphabetize letters and makes within letters
//   const alphabetizedCPbyMake = Object.keys(formattedData).reduce((arr, _letterKey) => {
//     // get the current letter object wich contains the makes for that letter in 'data'
//     const letterObj = formattedData[_letterKey]
//     // alphabetize the makes keys within data
//     const sortedDataKeys = Object.keys(letterObj.data).sort((cur, prev) => cur.toLowerCase() > prev.toLowerCase() ? 1 : -1)
//     // create new data object based on the sorted keys
//     const sortedMakes = sortedDataKeys.map((_make) => letterObj.data[_make])
//     const sortedData = sortedMakes.map((_makeArr) => {
//       return _makeArr.sort((a, b) => {
//         // if years are different, put smallest year ahead
//         if (a.Year !== b.Year) return a.Year < b.Year ? 1 : -1
//         //  if years are same and models are different, put smallest model ahead
//         if (a.Year === b.Year && a.Model !== b.Model) return a.Model < b.Model ? -1 : 1
//         if (a.Year === b.Year && a.Model === b.Model && a.SubModel !== b.SubModel) return a.SubModel < b.SubModel ? -1 : 1
//         if (a.Year === b.Year && a.Model === b.Model && a.SubModel === b.SubModel) return a.Cylinders < b.Cylinders ? -1 : 1

//         else return 0
//       })
//     })
//     // update existing data with sorted data
//     letterObj.data = sortedData
//     // add letterObj with sorted data to our letter array
//     arr.push(letterObj)
//     // sort letter array with new letter object added
//     arr.sort((cur, prev) => cur.letter > prev.letter ? 1 : -1)

//     return arr
//   }, [])
//   return alphabetizedCPbyMake
// }

// example: https://middlewarewowapi.azurewebsites.net/api/v1/Vehicles/Engines/ByYearMakeModelSubmodel?year=1985&makeID=54&ModelID=666&SubModelID=20
// const getPartsQuery = (_groupState) => getQueryData({
//   year: _groupState.Year.value || false,
//   makeID: _groupState.Make.value || false,
//   ModelID: _groupState.Model.value || false,
//   subModelID: _groupState.SubModel.value || false,
//   engineID: _groupState.Engine.value || false
// }, `${API}/v1/Microsite/Parts/ByYearMakeModelSubmodelEngine?`)
const getPartsQuery = async (_groupState) => {
  const vehicleConfig = await parseVehicleConfig(_groupState)
  const vehicle = vehicleConfig.Year.label + '-' + vehicleConfig.Make.label + '-' + vehicleConfig.Model.label + '-' + vehicleConfig.SubModel.label
  const vehicleEncoded = encodeURIComponent(vehicle)
  const response = await requestResource(`${API}/v2/${process.env.VUE_APP_SELECTED_COMPANY}/Parts/ByYmmsCategory?ymmsCatValue=${vehicleEncoded}`)
  return response
}

const parseVehicleConfig = async (vehicleConfig) => {
  return ensureYmmsDataLoaded().then(() => {
    if (!vehicleConfig.Year.label || !vehicleConfig.Make.label) {
      const selectedDataYear = ymmsAllData.find(item => item.yearID === vehicleConfig.Year.value)
      const yearLabel = vehicleConfig.Year.value
      vehicleConfig.Year.label = yearLabel
      const jsonDataMakes = selectedDataYear?.makes || []

      const selectedDataMake = jsonDataMakes?.find(option => option.MakeID === vehicleConfig.Make.value)
      const makeLabel = selectedDataMake?.MakeName
      vehicleConfig.Make.label = makeLabel
      const jsonDataModels = selectedDataMake?.Models || []

      const selectedDataModel = jsonDataModels.find(option => option.ModelID === vehicleConfig.Model.value)
      const modelLabel = selectedDataModel?.ModelName
      vehicleConfig.Model.label = modelLabel
      const jsonDataSubModels = selectedDataModel?.SubModels || []

      const selectedDataSubModel = jsonDataSubModels.find(option => option.SubModelID === vehicleConfig.SubModel.value)
      const subModelLabel = selectedDataSubModel?.SubModelName
      vehicleConfig.SubModel.label = subModelLabel
      const jsonDataEngines = selectedDataModel?.EngineLabelPartTypes || []

      const engineLabel = jsonDataEngines[0]?.EngineLabel
      vehicleConfig.Engine.label = engineLabel
    }
    return vehicleConfig
  })
}

// example: https://middlewaremicrositeflatdata20211026.azurewebsites.net/api/v1/CMS/Environment?forEnv=autozone
const getCMSApiKeyQuery = (forEnv = false) => getQueryData({
  forEnv
}, `${API}/v1/CMS/Environment?`)

// https://20230112mwmsmaindatasvc .azurewebsites.net/api/v1/Vehicles/MakeModel/Search?makeName=Ford&modelSubmodelValues=F-250%20Super%20Duty%20XLT%C2%A0Lariat
const getMakeModelSearchQuery = (makeName, modelSubmodelValues) => getQueryData({
  makeName,
  modelSubmodelValues
}, `${API}/v1/Microsite/MakeModel/Search?`)

const queriesMap = {
  Year: getYearsQuery,
  YearAPI: getYearsQuery,
  Make: getMakesQuery,
  Model: getModelsQuery,
  SubModel: getSubModelsQuery,
  Engine: getEnginesQuery,
  Parts: getPartsQuery,
  CompatibleVehicles: getCompatibleVehiclesQuery,
  Search: getSearchQuery,
  CMSApiKey: getCMSApiKeyQuery,
  MakeModel: getMakeModelSearchQuery
}

const getAPIObjFor = (_componentName) => {
  return queriesMap[_componentName]
}
export {
  getAPIObjFor
}
