<template>
  <el-button
    :type="type"
    :disabled="disabled"
    :target="_blank"
    round
    :icon="icon"
    :class="{ noicon: !icon, 'icon-right':iconRight }"
    ><slot
  /></el-button>
</template>

<script>
import { ref } from 'vue'
export default {
  props: {
    type: { type: String, default: 'primary' },
    icon: { type: String, default: null },
    iconRight: { type: Boolean, defaualt: false },
    disabled: { type: Boolean, default: false }
  },
  setup (props) {
    const hasIcon = ref(props.icon)
    return { hasIcon }
  }
}
</script>

<style  lang="scss">
.el-button {
  margin-left: 0px !important;
  color: $mf-btn-font-color !important;
  border: none !important;
  font-weight: bold !important;
  &.el-button--primary {
    background-color: var(--btn-bg-color) !important;
  }
}
.el-button:hover {
  color: $mf-btn-font-color !important;
  &.el-button--text {
    color: var(--btn-bg-hover-color) !important;
  }
  &.el-button--primary {
    background-color: var(--btn-bg-hover-color) !important;
  }
}

.el-button--text {
  padding: 0 !important;
  color: var(--link-color) !important;
  i {
    margin-right: 5px;
  }
}
.icon-right{
  i{
    float:right;
  }
  span{
    margin-right: 5px;
  }
}
</style>
